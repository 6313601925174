import { type ReactElement } from 'react';
import parse from 'html-react-parser';

type ActoProps = {
	readonly descripcion: string;
	readonly logos: string[];
};

const Acto = ({ descripcion, logos }: ActoProps): ReactElement => (
	<div className="c-jb-home__actos-card">
		<p className="c-jb-home__actos-info">{parse(descripcion)}</p>
		<div className="c-jb-home__actos-brands">
			{logos.map((logo) => (
				<img key={logo} src={logo} alt="" />
			))}
		</div>
	</div>
);

export default Acto;
