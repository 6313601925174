export const HOMEPAGE = process.env.REACT_APP_HOME_PAGE;
export const DATAFOLDER = process.env.REACT_APP_DATA_FOLDER;
export const MENU = {
	HOME: {
		route: '/',
		menu: 'Inicio',
	},
	INICIO: {
		route: '/inicio',
		menu: 'Inicio',
	},
	PRESENTACION: {
		route: '/presentacion',
		menu: 'Presentación',
	},
	SESION_PLENARIA: {
		route: '/sesion-plenaria',
		menu: '112ª Sesión Plenaria',
	},
	SESION_PLENARIA_PRESENTACION: {
		route: '/sesion-plenaria/presentacion',
		menu: '112ª Sesión Plenaria',
	},
	SESION_PLENARIA_PROGRAMA: {
		route: '/sesion-plenaria/programa',
		menu: '112ª Sesión Plenaria',
	},
	SESION_PLENARIA_INSCRIPCION: {
		route: '/sesion-plenaria/inscripcion',
		menu: '112ª Sesión Plenaria',
	},
	SESION_PLENARIA_HOTEL: {
		route: '/sesion-plenaria/hotel',
		menu: '112ª Sesión Plenaria',
	},
	JORNADA_NOTARIAL: {
		route: '/jornada-notarial',
		menu: 'XIX Jornadas Notariales Iberoamericanas',
	},
	JORNADA_NOTARIAL_PRESENTACION: {
		route: '/jornada-notarial/presentacion',
		menu: 'XIX Jornadas Notariales Iberoamericanas',
	},
	JORNADA_NOTARIAL_PROGRAMA: {
		route: '/jornada-notarial/programa',
		menu: 'XIX Jornadas Notariales Iberoamericanas',
	},
	JORNADA_NOTARIAL_INSCRIPCION: {
		route: '/jornada-notarial/inscripcion',
		menu: 'XIX Jornadas Notariales Iberoamericanas',
	},
	JORNADA_NOTARIAL_HOTEL: {
		route: '/jornada-notarial/hotel',
		menu: 'XIX Jornadas Notariales Iberoamericanas',
	},
	SALA_PRENSA: {
		route: '/sala-prensa',
		menu: 'Sala de prensa',
	},
	SALA_PRENSA_NOTAS: {
		route: '/sala-prensa/notas-de-prensa',
		menu: 'Sala de prensa',
	},
	SALA_PRENSA_GALERIA: {
		route: '/sala-prensa/galeria',
		menu: 'Sala de prensa',
	},
	POLITICA_PRIVACIDAD: {
		route: '/politica-privacidad',
		menu: 'Política de privacidad',
	},
	DATOS_CONTACTO: {
		route: '/datos-contacto',
		menu: 'Datos de contacto',
	},
	AVISO_LEGAL: {
		route: '/aviso-legal',
		menu: 'Aviso legal',
	},
	MAPA_WEB: {
		route: '/mapa-web',
		menu: 'Mapa web',
	},
	POLITICA_COOKIES: {
		route: '/politica-cookies',
		menu: 'Política de cookies',
	},
	INFO: {
		route: '/informacion-interes',
		menu: 'Información de interés',
	},
	INFO_VISADOS: {
		route: '/informacion-interes/visados',
		menu: 'Información visados',
	},
	INFO_VESTIMENTA: {
		route: '/informacion-interes/vestimenta',
		menu: 'Código de vestimenta',
	},
};

export const ROUTES: Record<string, string> = {
	HOME: MENU.HOME.route,
	INICIO: MENU.INICIO.route,
	PRESENTACION: MENU.PRESENTACION.route,
	SESION_PLENARIA: MENU.SESION_PLENARIA.route,
	SESION_PLENARIA_PRESENTACION: MENU.SESION_PLENARIA_PRESENTACION.route,
	SESION_PLENARIA_PROGRAMA: MENU.SESION_PLENARIA_PROGRAMA.route,
	SESION_PLENARIA_INSCRIPCION: MENU.SESION_PLENARIA_INSCRIPCION.route,
	SESION_PLENARIA_HOTEL: MENU.SESION_PLENARIA_HOTEL.route,
	JORNADA_NOTARIAL: MENU.JORNADA_NOTARIAL.route,
	JORNADA_NOTARIAL_PRESENTACION: MENU.JORNADA_NOTARIAL_PRESENTACION.route,
	JORNADA_NOTARIAL_PROGRAMA: MENU.JORNADA_NOTARIAL_PROGRAMA.route,
	JORNADA_NOTARIAL_INSCRIPCION: MENU.JORNADA_NOTARIAL_INSCRIPCION.route,
	JORNADA_NOTARIAL_HOTEL: MENU.JORNADA_NOTARIAL_HOTEL.route,
	SALA_PRENSA: MENU.SALA_PRENSA.route,
	SALA_PRENSA_NOTAS: MENU.SALA_PRENSA_NOTAS.route,
	SALA_PRENSA_GALERIA: MENU.SALA_PRENSA_GALERIA.route,
	POLITICA_PRIVACIDAD: MENU.POLITICA_PRIVACIDAD.route,
	DATOS_CONTACTO: MENU.DATOS_CONTACTO.route,
	AVISO_LEGAL: MENU.AVISO_LEGAL.route,
	MAPA_WEB: MENU.MAPA_WEB.route,
	POLITICA_COOKIES: MENU.POLITICA_COOKIES.route,
	INFO: MENU.INFO.route,
	INFO_VISADOS: MENU.INFO_VISADOS.route,
	INFO_VESTIMENTA: MENU.INFO_VESTIMENTA.route,
};
