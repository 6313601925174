const es = {
	service: {
		required: {
			description: 'Este servicio es necesario siempre',
			title: '(siempre requerido)',
		},
		disableAll: {
			description: 'Utilice este interruptor para activar o desactivar todos los servicios.',
			title: 'Activar o desactivar todos los servicios',
		},
		purpose: 'Finalidad',
		purposes: 'Finalidades',
	},
	consentModal: {
		description:
			'Aquí puede evaluar y personalizar los servicios que nos gustaría utilizar en este sitio web. Habilite o deshabilite los servicios como considere oportuno.',
		privacyPolicy: {
			name: 'política de cookies',
		},
		title: 'Configuración de cookies',
	},
	consentNotice: {
		changeDescription: 'Ha habido cambios en las cookies desde su última visita. Debe renovar su consentimiento.',
		description:
			'Utilizamos cookies propias y de terceros para Analíticas. Haga clic en botón “Aceptar” si desea admitir todas las cookies, o haga clic en el texto "Quiero elegir..." para admitir sólo las cookies que desee o rechazarlas todas (excepto las funcionales, que son necesarias).',
		privacyPolicy: {
			name: 'política de cookies',
		},
		learnMore: 'Quiero elegir...',
	},
	purposes: {
		functional: {
			title: 'Funcionales',
			description:
				'Estos servicios son esenciales para el correcto funcionamiento de este sitio web. No puede desactivarlos ya que la página no funcionaría correctamente.',
		},
		analytics: {
			title: 'Analíticas',
			description:
				'Estos servicios nos permiten realizar un seguimiento y análisis de las visitas que recibe la web.',
		},
		marketing: {
			title: 'Marketing',
			description:
				'Estos servicios procesan información personal para mostrarle contenido relevante sobre productos, servicios o temas que puedan interesarle.',
		},
	},
	privacyPolicy: {
		name: 'política de cookies',
		text: 'Para saber más, por favor lea nuestra {privacyPolicy}.',
	},
	purposeItem: {
		service: 'servicio',
		services: 'servicios',
	},
	ok: 'Aceptar',
	decline: 'Descartar todas',
	acceptSelected: 'Aceptar seleccionadas',
	poweredBy: ' ',
};

export default es;
