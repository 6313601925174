import { useEffect, useState } from 'react';
import { ReactComponent as LogoNotario } from '../../assets/images/cgn.svg';
import { ReactComponent as LogoUnion } from '../../assets/images/uinl.svg';
import { getHomeTitle } from '../../services/title.service';
import type { ITitle } from '../../interfaces/model/ITitle';

export default function Header(): React.ReactElement {
	const [title, setTitle] = useState<ITitle | undefined>(undefined);

	const loadTitle = async () => {
		const { data } = await getHomeTitle();
		setTitle(data);
	};

	useEffect(() => {
		loadTitle();
	}, []);

	return (
		<header className="c-jb-header">
			<LogoNotario className="c-jb-header__brand c-jb-header__brand--cgn" />
			<LogoUnion className="c-jb-header__brand c-jb-header__brand--uinl" />
			<div className="c-jb-header__hgroup">
				<h1 className="c-jb-header__tit">{title?.titulo}</h1>
				<p className="c-jb-header__sub-tit">{title?.fecha}</p>
			</div>
		</header>
	);
}
