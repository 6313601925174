import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import type { ISelectChoice, ISelectPrice } from '../../interfaces/AUI/ISelect';

export type IFormAcompanante = {
	nombre: string;
	primerApellido: string;
	segundoApellido: string;
	excursiones: string[];
};

export type IFormInscripcion = {
	nombre?: string;
	primerApellido?: string;
	segundoApellido?: string;
	direccion?: string;
	localidad?: string;
	pais?: string;
	email?: string;
	reEmail?: string;
	telefono?: string;
	actividades?: string;
	cena?: string[];
	excursiones: string;
	acompanantes?: IFormAcompanante[];
	aceptar?: string[];
};

export const defaultInscripcionValues: IFormInscripcion = {
	nombre: '',
	primerApellido: '',
	segundoApellido: '',
	direccion: '',
	localidad: '',
	pais: '',
	email: '',
	reEmail: '',
	telefono: '',
	actividades: '',
	cena: [],
	excursiones: '',
	acompanantes: [],
	aceptar: [],
};

export const defaultAcompananteValues: IFormAcompanante = {
	nombre: '',
	primerApellido: '',
	segundoApellido: '',
	excursiones: [],
};

export const ACTIVIDADES: ISelectPrice[] = [
	{
		label: 'XIX Jornadas Notariales Iberoamericanas',
		value: 'jornadas',
		price: 450,
		explanation: '(Incluye almuerzo del día 18 de septiembre)',
	},
	{
		label: '112ª Plenario de la comisión de Asuntos Americanos (CAAM)',
		value: 'plenario',
		price: 500,
		explanation: '(Incluye almuerzos de los días 16 y 17 de septiembre)',
	},
	{ label: 'Ambos', value: 'ambos', price: 900 },
];

export const CENA: ISelectPrice[] = [{ label: 'Asistencia a la cena', value: 'cena', price: 140 }];

export const EXCURSIONES: ISelectChoice = {
	label: 'Excursión en barco y almuerzo',
	price: 0,
	radioOptions: [
		{ label: 'Si', value: 'Si' },
		{ label: 'No', value: 'No' },
	],
};
export const EXCURSIONES_ACOMPANIANTE: ISelectPrice[] = [
	{ label: 'Excursión en barco y almuerzo', value: 'barco', price: 95 },
	{ label: 'Asistencia a la cena', value: 'cena', price: 140 },
];

const VALIDATION_REQUIRED = yup.string().required('Campo obligatorio');
const VALIDATION_TELEPHONE = yup
	.string()
	.matches(/^\+?\d{9,}$/, 'Debe tener mínimo 9 dígitos y un formato correcto')
	.required('Campo obligatorio');

export const resolver = yupResolver(
	yup.object().shape({
		nombre: VALIDATION_REQUIRED,
		primerApellido: VALIDATION_REQUIRED,
		direccion: VALIDATION_REQUIRED,
		localidad: VALIDATION_REQUIRED,
		pais: VALIDATION_REQUIRED,
		email: yup
			.string()
			.email('Ingrese un correo electrónico válido')
			.required('El correo electrónico es requerido'),
		reEmail: yup
			.string()
			.email('Ingrese un correo electrónico válido')
			.oneOf([yup.ref('email')], 'Los correos electrónicos deben coincidir')
			.required('Confirme su correo electrónico'),
		telefono: VALIDATION_TELEPHONE,
		acompanantes: yup.array().of(
			yup.object({
				nombre: VALIDATION_REQUIRED,
				primerApellido: VALIDATION_REQUIRED,
			}),
		),
		actividades: yup.string().min(1, 'Debe seleccionar como mínimo una actividad'),
		aceptar: yup.array().min(1, 'Debe aceptar las condiciones'),
		excursiones: yup.string().required('Seleccione una opción'),
	}),
);
