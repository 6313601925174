import type { ITab } from '../../../interfaces/AUI/ITabNavigation';

export const TABS: ITab[] = [
	/*
	{
		id: 1,
		header: 'Presentación',
	},
	*/
	{
		id: 2,
		header: 'Programa',
	},
	{
		id: 3,
		header: 'Inscripción',
	},
	{
		id: 4,
		header: 'Alojamiento',
	},
];
