import { DATAFOLDER } from '../constants/routes.constants';
import type { IVestimenta } from '../interfaces/model/IVestimenta';
import type { IVisado } from '../interfaces/model/IVisado';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_VISADOS: `${DATAFOLDER}/data/info-interes/visados.json`,
	GET_VESTIMENTA: `${DATAFOLDER}/data/info-interes/vestimenta.json`,
});

export const getInfoVisados = async () => callGetExternalApi<IVisado>({ url: getBackendUrl().GET_VISADOS });
export const getInfoVestimenta = async () => callGetExternalApi<IVestimenta>({ url: getBackendUrl().GET_VESTIMENTA });
