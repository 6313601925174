import { useEffect, useState, type ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import type { Contacto } from '../../interfaces/model/IContancto';

import Back from '../../components/Back';

import { getContacto } from '../../services/contacto.service';

const DatosContacto = (): ReactElement => {
	const [contactoData, setContactoData] = useState<Contacto>();
	const history = useHistory();

	const loadData = async () => {
		const { data } = await getContacto();
		setContactoData(data);
	};

	const onBackClick = () => {
		history.goBack();
	};

	useEffect(() => {
		loadData();
	}, []);

	return contactoData ? (
		<div className="o-wrapper">
			<Back onBackClick={onBackClick} />
			<h2 className="[ c-jb-heading ] [ u-txt-center ]">Datos de contacto</h2>
			<div className="[ c-jb-contact ] [ u-mt-32 ]">
				<address className="c-jb-contact__info">{parse(contactoData.contenido)}</address>

				<iframe
					allowFullScreen
					sandbox="allow-scripts"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.762251865569!2d-3.646057984602798!3d40.45839877936027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422ede51948145%3A0x25b3c04edd69a991!2sCalle%20de%20Silvano%2C%2055%2C%2028043%20Madrid!5e0!3m2!1ses!2ses!4v1575363021593!5m2!1ses!2ses"
					className="c-jb-contact__map"
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				/>
			</div>
		</div>
	) : (
		<></>
	);
};

export default DatosContacto;
