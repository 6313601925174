import { useEffect, useState, type ReactElement } from 'react';
import parse from 'html-react-parser';

import type { IVestimenta } from '../../../interfaces/model/IVestimenta';

import { getInfoVestimenta } from '../../../services/infoInteres.service';

const Vestimenta = (): ReactElement => {
	const [vestimentaData, setVestimentaData] = useState<IVestimenta>();

	const loadData = async () => {
		const { data } = await getInfoVestimenta();
		setVestimentaData(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	const renderContent = () =>
		vestimentaData?.contenido?.map(({ id, titulo, senioras, caballeros }) => (
			<li key={id} className="[ u-mt-24 ]">
				{parse(titulo)}
				<ul>
					<li className="[ u-mt-8 ]">{parse(senioras)}</li>
					<li className="[ u-mt-8 ]">{parse(caballeros)}</li>
				</ul>
			</li>
		));
	return vestimentaData ? (
		<>
			<h2 className="c-jb-heading">{parse(vestimentaData.titulo)}</h2>
			<ul className="[ u-mt-16 ]">{renderContent()}</ul>
		</>
	) : (
		<></>
	);
};

export default Vestimenta;
