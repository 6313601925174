import React from 'react';
import { classNames } from 'primereact/utils';

import AUIInputCheckboxField from '@ancert/aui-input-checkbox-field';
import AUIField from '@ancert/aui-field';

import type { ISelectPrice } from '../../interfaces/AUI/ISelect';

import { formatNumberDecimals } from '../../utils/numberUtils';

type InputCheckboxPriceProps = {
	readonly name: string;
	readonly label: string;
	readonly className?: string;
	readonly checkboxArray: ISelectPrice[];
	readonly controlRef: any;
	readonly errors: any;
};

const InputCheckboxPrice = ({
	name,
	label,
	className,
	checkboxArray,
	controlRef,
	errors,
}: InputCheckboxPriceProps): React.ReactElement => {
	const classNameCheckBoxPrice = classNames('c-jb-checkbox-price', className);

	return (
		<div className={classNameCheckBoxPrice}>
			<AUIField condensed label={label} className="c-jb-checkbox-price__label">
				<ul className="c-jb-checkbox-price__list">
					{checkboxArray.map(({ label, value, price }) => (
						<li key={value} className="c-jb-checkbox-price__item">
							<AUIInputCheckboxField
								required
								classNameField="c-jb-field--no-feedback"
								name={name}
								columns={1}
								controlRef={controlRef}
								errors={{}}
								checkboxArray={[{ label, value }]}
								checkSize="lg"
							/>
							<p className="c-jb-checkbox-price__price">
								<strong>{price > 0 ? `${formatNumberDecimals(price)} €` : 'Gratis'}</strong>
							</p>
						</li>
					))}
				</ul>
			</AUIField>
		</div>
	);
};

export default InputCheckboxPrice;
