import React, { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';

import type { ITab } from '../../interfaces/AUI/ITabNavigation';

type TabNavigationProps = {
	readonly tabs: ITab[];
	readonly activeTab?: ITab['id'];
	readonly className?: string;
	readonly onTabChange: (id: ITab['id']) => void;
};

const TabNavigation = ({ tabs, activeTab = 1, className, onTabChange }: TabNavigationProps): React.ReactElement => {
	const [activeIndex, setActiveIndex] = useState<ITab['id']>(1);
	const classNameTabNavigation = classNames('c-jb-tab-navigation', className);

	useEffect(() => {
		setActiveIndex(activeTab);
	}, [activeTab]);

	const onClick = (id: ITab['id']) => {
		setActiveIndex(id);
		onTabChange(id);
	};

	return (
		<ul className={classNameTabNavigation}>
			{tabs.map(({ id, header }) => (
				<li key={id} className={activeIndex === id ? 'active' : ''} onClick={() => onClick(id)}>
					<button className="c-jb-tab-navigation__btn" type="button">
						{header}
					</button>
				</li>
			))}
		</ul>
	);
};

export default TabNavigation;
