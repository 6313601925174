import { useEffect, useState, type ReactElement } from 'react';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';

import type { IPresentacion } from '../../interfaces/model/IPrograma';

import Back from '../../components/Back';

import { getPoliticaCookies } from '../../services/politicas.service';

const PoliticaCookies = (): ReactElement => {
	const history = useHistory();
	const [politicaCookies, setPoliticaCookies] = useState<IPresentacion>();

	const onBackClick = () => {
		history.goBack();
	};

	const loadData = async () => {
		const { data } = await getPoliticaCookies();
		setPoliticaCookies(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	return politicaCookies ? (
		<div className="c-jb-privacy o-wrapper">
			<Back onBackClick={onBackClick} />
			<h2 className="[ c-jb-heading ] [ u-txt-center ]">Politica de Cookies</h2>
			<div className=" [ u-mt-16 ]">{parse(politicaCookies.content)}</div>
		</div>
	) : (
		<></>
	);
};

export default PoliticaCookies;
