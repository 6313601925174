import React, { useState } from 'react';
import AUIMessage from '@ancert/aui-message';

const AdvertenciaSeguridad = (): React.ReactElement => {
	const [visible, setVisible] = useState(true);

	return visible ? (
		<div className="u-mt-16">
			<AUIMessage
				content="Por motivos de seguridad los congresistas inscritos que no tengan el alojamiento en el Hotel Abama no podrán
		asistir a la inauguración del II Encuentro de los Ministros de Justicia de Iberoamérica (CMJIB) y de los Países
		de Lengua Portuguesa (CMJPLOP) fijada para el jueves 19"
				mode="filled"
				icon="info"
				status="warning"
				onClose={() => setVisible(false)}
			/>
		</div>
	) : (
		<></>
	);
};

export default AdvertenciaSeguridad;
