import { useEffect } from 'react';
import { node } from 'prop-types';
import * as Klaro from 'klaro/dist/klaro-no-css';
import klaroConfig from './klaroConfig';

import 'klaro/dist/klaro.css';

const KlaroCookies = ({ children }) => {
	useEffect(() => {
		window.klaro = Klaro;
		window.klaroConfig = klaroConfig;
		Klaro.setup(klaroConfig);
	}, []);

	return <>{children}</>;
};

KlaroCookies.propTypes = {
	children: node.isRequired,
};

export default KlaroCookies;
