import { DATAFOLDER } from '../constants/routes.constants';
import type { ISelect } from '../interfaces/AUI/ISelect';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_PAISES: `${DATAFOLDER}/data/paises.json`,
});

export const getPaises = async () => callGetExternalApi<ISelect[]>({ url: getBackendUrl().GET_PAISES });
