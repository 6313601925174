import React from 'react';
import { ReactComponent as LogoNotario } from '../../assets/images/cgn.svg';
import { ReactComponent as LogoUnion } from '../../assets/images/uinl.svg';
import parse from 'html-react-parser';

type BlockProps = {
	readonly titulo?: string;
	readonly content?: string;
};

const Block = ({ titulo, content }: BlockProps): React.ReactElement => (
	<div className="c-jb-block">
		{titulo && parse(titulo)}
		{content && parse(content)}
		<div className="u-mt-24 c-jb-block__logos">
			<LogoNotario className="c-jb-block__img" />
			<LogoUnion className="c-jb-block__img" />
		</div>
	</div>
);

export default Block;
