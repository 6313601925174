import { useState } from 'react';

import type { IInscripcionData, IInscripcionResponse } from '../../interfaces/services/IInscricpion';

import { postInscripcion } from '../../services/inscripcion.service';

type useSaveProps = {
	saved: (data: IInscripcionResponse) => void;
	error: () => void;
};

export const useSave = ({ saved, error }: useSaveProps) => {
	const [saving, setSaving] = useState(false);

	const save = async (saveData: IInscripcionData) => {
		setSaving(true);
		const { code, data } = await postInscripcion(saveData);
		if (code === 200) {
			saved(data);
		} else {
			error();
		}

		setSaving(false);
	};

	return { saving, save };
};
