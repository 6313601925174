import { useState, useEffect } from 'react';

export const useSessionStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
	const getSessionStorageValue = (): T => {
		const item = sessionStorage.getItem(key);
		return item ? JSON.parse(item) : initialValue;
	};

	const [value, setValue] = useState<T>(getSessionStorageValue);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	const updateValue = (newValue: T) => {
		setValue(newValue);
	};

	return [value, updateValue];
};
