import { useEffect, useState, type ReactElement } from 'react';
import parse from 'html-react-parser';
import YouTube from 'react-youtube';

import type { IPresentacion } from '../../interfaces/model/IPrograma';

import joseAngelMartinez from '../../assets/images/img_JoseAngelMartinezSanchiz.jpg';
import reyClausura from '../../assets/images/img_Rey_Clausura .jpg';

import { getPresentacion } from '../../services/programa.service';

const videoProps = {
	height: 'auto',
	width: '100%',
};

const Presentacion = (): ReactElement => {
	const [presentacionData, setPresentacionData] = useState<IPresentacion>();

	const loadData = async () => {
		const { data } = await getPresentacion();
		setPresentacionData(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	return presentacionData ? (
		<div className="o-wrapper">
			<div className="c-jb-presentation">
				<div>
					<h2 className="c-jb-heading">{parse(presentacionData.titulo)}</h2>
					<div className="[ c-jb-presentation__info ] [ u-mt-16 ]">{parse(presentacionData.content)}</div>
					<div className="[ c-jb-presentation__foot ] [ u-mt-32 ]">{parse(presentacionData.foot)}</div>
				</div>

				<figure className="c-jb-presentation__gallery">
					<img className="c-jb-presentation__img" src={joseAngelMartinez} alt="" />
					<img className="c-jb-presentation__img" src={reyClausura} alt="" />
				</figure>

				<div className="c-jb-presentation__videos">
					<figure className="c-jb-presentation__videos-figure">
						<YouTube className="c-jb-video" videoId={presentacionData.video1.id} opts={videoProps} />
						<figcaption className="c-jb-video__caption">{presentacionData.video1.title}</figcaption>
					</figure>
					<figure className="c-jb-presentation__videos-figure">
						<YouTube className="c-jb-video" videoId={presentacionData.video2.id} opts={videoProps} />
						<figcaption className="c-jb-video__caption">{presentacionData.video2.title}</figcaption>
					</figure>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default Presentacion;
