export const HOTEL_URL = 'https://www.ritzcarlton.com/es/hotels/tfsrz-the-ritz-carlton-abama/overview/';

export const VIDEOS = {
	VIDEO1: 'lU2ENHhSf_I',
	VIDEO2: 'f2c4kUc9Vo4',
};

export const APP = 'jornadas-tenerife';

export const TRACKING_ID = 'G-4QPPH0P28V';

export const SITE_KEY = '6LfDlpApAAAAAJlLlr05SmY6DYq9imOAd4Q5-qBN';
