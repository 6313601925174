import { DATAFOLDER } from '../constants/routes.constants';
import type { IInscripcionData, IInscripcionResponse } from '../interfaces/services/IInscricpion';
import { callGetExternalApi, callPostExternalApi } from '../utils/externalApiCall';

const baseURL = `${process.env.REACT_APP_BACK}`;

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	POST_INSCRIPCION: `${baseURL}inscripcion-eventos/rest/public/inscripcion`,
	INSCRIPCION_OK: `${DATAFOLDER}/data/inscripcion-ok.json`,
});

export const postInscripcion = async (data: IInscripcionData) =>
	callPostExternalApi<IInscripcionResponse>({ url: getBackendUrl().POST_INSCRIPCION, data });

export const getInscripcionOk = async () => callGetExternalApi<any>({ url: getBackendUrl().INSCRIPCION_OK });
