import { DATAFOLDER } from '../constants/routes.constants';
import type { IPrograma, IPresentacion } from '../interfaces/model/IPrograma';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_PRESENTACION: `${DATAFOLDER}/data/presentacion.json`,
	GET_PRESENTACION_SESION: `${DATAFOLDER}/data/sesion-plenaria/presentacion.json`,
	GET_PROGRAMA_SESION: `${DATAFOLDER}/data/sesion-plenaria/programa.json`,
	GET_PRESENTACION_JORNADA: `${DATAFOLDER}/data/jornada-notarial/presentacion.json`,
	GET_PROGRAMA_JORNADA: `${DATAFOLDER}/data/jornada-notarial/programa.json`,
});

const getPresentacion = async () => callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_PRESENTACION });

const getPresentacionSesion = async () =>
	callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_PRESENTACION_SESION });

const getProgramaSesion = async () => callGetExternalApi<IPrograma[]>({ url: getBackendUrl().GET_PROGRAMA_SESION });

const getPresentacionJornada = async () =>
	callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_PRESENTACION_JORNADA });

const getProgramaJornada = async () => callGetExternalApi<IPrograma[]>({ url: getBackendUrl().GET_PROGRAMA_JORNADA });

export { getPresentacion, getPresentacionSesion, getProgramaSesion, getPresentacionJornada, getProgramaJornada };
