import React from 'react';
import parse from 'html-react-parser';
import type { IPrograma } from '../../interfaces/model/IPrograma';

type ProgramProps = {
	readonly horario: IPrograma['horario'];
	readonly titulo: IPrograma['titulo'];
	readonly subtitulo: IPrograma['subtitulo'];
	readonly participantes: IPrograma['participantes'];
	readonly color: IPrograma['color'];
};

const Program = ({ color, horario, titulo, subtitulo, participantes }: ProgramProps): React.ReactElement => (
	<div className="[ c-jb-program__content ] [ u-mt-16 ]">
		<div className="c-jb-program__event">
			<p className="c-jb-program__time">{horario}</p>
			{titulo && (
				<p style={color ? { color } : {}} className="c-jb-program__tit">
					{parse(titulo)}
				</p>
			)}
			{subtitulo && <p className="u-mt-8">{parse(subtitulo)}.</p>}
		</div>

		{participantes && participantes.length > 0 && (
			<div className="c-jb-program__info">
				<p className="c-jb-program__participant">Participantes:</p>
				<ul className="[ c-jb-program__list ] [ u-mt-8 ]">
					{participantes.map(({ id, nombre, descripcion, moderador }) => (
						<li key={id}>
							{parse(nombre)}
							{moderador ? <> (moderador): </> : ', '} {parse(descripcion)}.
						</li>
					))}
				</ul>
			</div>
		)}
	</div>
);

export default Program;
