import React, { type ReactNode } from 'react';

import { Header, Nav, Footer } from '../index';

type LayoutProps = {
	readonly children?: ReactNode;
};

export default function Layout({ children }: LayoutProps): React.ReactElement {
	return (
		<div className="o-layout">
			<Header />
			<Nav />
			<div className="o-layout__body">
				<main className="o-main">{children}</main>
			</div>
			<Footer />
		</div>
	);
}
