import React, { useState, useImperativeHandle } from 'react';

import { AUIModal } from '@ancert/aui-modal';
import AUISpinner from '@ancert/aui-spinner';

type LoadingModalProps = {
	readonly message: string;
};

const LoadingModal = React.forwardRef(({ message }: LoadingModalProps, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};
	const closeModal = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		open: () => openModal(),
		close: () => closeModal(),
	}));

	return (
		<AUIModal show={isOpen} size="xs" closeable={false} onClose={closeModal}>
			<div className="c-jb-loading-modal">
				<AUISpinner size="xl" />
				<div>{message}</div>
			</div>
		</AUIModal>
	);
});

LoadingModal.displayName = 'LoadingModal';
export default LoadingModal;
