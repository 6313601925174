import { Link } from 'react-router-dom';

import type { MapData, RouteNode } from '../../interfaces/AUI/IMapWeb';

type RenderMapWebProps = {
	readonly data: MapData[][];
};

/**
 * Genera un enlace.
 */
const rendeLink = (
	route: RouteNode['route'] | undefined,
	title: RouteNode['title'],
	external: RouteNode['external'] | undefined,
	download: RouteNode['download'] | undefined,
) => {
	if (route) {
		if (external) {
			return (
				<a download={download} href={route} target="_blank" rel="noopener noreferrer">
					{title}
				</a>
			);
		}

		return <Link to={route}>{title}</Link>;
	}

	return title;
};

const RenderMapWeb = ({ data }: RenderMapWebProps) => (
	<ul className="c-jb-map-web__lists">
		{data.map((dataNode) => (
			<li key={dataNode.toString()}>
				<ul className="[ c-jb-map-web__list ] [ u-mt-32 ]">
					{dataNode.map(({ route, title, subroutes, external, download }) => (
						<li key={route} className="c-jb-map-web__item">
							{rendeLink(route, title, external, download)}
							{subroutes && subroutes.length > 0 && (
								<ul className="c-jb-map-web__sublist">
									{subroutes.map(({ route, title, external, download }) => (
										<li key={route} className="c-jb-map-web__subitem">
											{rendeLink(route, title, external, download)}
										</li>
									))}
								</ul>
							)}
						</li>
					))}
				</ul>
			</li>
		))}
	</ul>
);

export default RenderMapWeb;
