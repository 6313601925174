import { type ReactElement, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import type { ITab } from '../../interfaces/AUI/ITabNavigation';
import TabNavigation from '../../components/TabNavigation';
import { TABS } from './tabs/tabs';
import { ROUTES } from '../../constants/routes.constants';
import { searchKeyByValue } from '../../utils/routeUtils';
import Visados from './tabs/Visados';
import Vestimenta from './tabs/Vestimenta';

const TABS_ROUTES: Record<number, string> = {
	1: ROUTES.INFO_VISADOS,
	2: ROUTES.INFO_VESTIMENTA,
};

const InformacionInteres = (): ReactElement => {
	const [activeTab, setActiveTab] = useState<ITab['id']>(1);
	const history = useHistory();

	const onTabChange = (id: ITab['id']) => {
		history.push(TABS_ROUTES[id]);
		setActiveTab(id);
	};

	useEffect(() => {
		setActiveTab(
			history.location.pathname === ROUTES.INFO ? 1 : searchKeyByValue(TABS_ROUTES, history.location.pathname),
		);
	}, [history.location.pathname]);

	return (
		<div className="o-wrapper">
			<TabNavigation tabs={TABS} activeTab={activeTab} onTabChange={onTabChange} />
			<div className="u-mt-32">
				<Switch>
					<Route exact path={[ROUTES.INFO, ROUTES.INFO_VISADOS]} component={Visados} />
					<Route exact path={[ROUTES.INFO_VESTIMENTA]} component={Vestimenta} />
				</Switch>
			</div>
		</div>
	);
};

export default InformacionInteres;
