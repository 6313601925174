import { type ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import RenderMapWeb from '../../components/RenderMapWeb';
import Back from '../../components/Back';

import { map } from './map';

const MapaWeb = (): ReactElement => {
	const history = useHistory();

	const onBackClick = () => {
		history.goBack();
	};

	return (
		<div className="o-wrapper">
			<Back onBackClick={onBackClick} />
			<h2 className="[ c-jb-heading ] [ u-txt-center ]">Mapa web</h2>
			<div className="c-jb-map-web">
				<RenderMapWeb data={map} />
			</div>
		</div>
	);
};

export default MapaWeb;
