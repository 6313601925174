import es from './languajes/es';

const klaroConfig = {
	translations: {
		zz: {
			privacyPolicyUrl: '/download/cookies-policy',
		},
		es,
		en: es,
	},
	services: [
		{
			name: 'BIGIp',
			title: 'BIGIp',
			purposes: ['functional'],
			required: true,
		},
		{
			name: 'klaro',
			title: 'Klaro',
			purposes: ['functional'],
			required: true,
		},
	],
};

export default klaroConfig;
