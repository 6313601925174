import axios, { type AxiosError } from 'axios';
import generateBackendUrl from '../constants/api.routes.constants';
import type { ErrorMessage, ProcessedData } from '../interfaces/utils/axios.interface';
import generateToken from '../services/jwt.service';

const forceLogout = () => {
	sessionStorage.removeItem('jwt');
};

const axiosInstance = axios.create({
	baseURL: process.env.BASE_URL,
});

/**
 * Interceptor para las peticiones al backend, en caso de tener
 * informada una credencial en el sessionStorage esta se adjunta
 * en la cabecera para ser enviada en cada petición.
 */
axiosInstance.interceptors.request.use(
	async (config) => {
		const conf = config;
		const jwt = sessionStorage.getItem('jwt');
		if (jwt) {
			conf.headers.Authorization = `Bearer ${jwt}`;
		}
		return conf;
	},
	(error) => Promise.reject(error),
);

/**
 * Interceptor para las respuestas del backend, en caso que la
 * petición devuelva un código de error HTTP 401 porque carece
 * de credenciales válidas de autenticación se va a buscar una
 * nueva credencial y se lanza una nueva petición con la
 * credencial actualizada.
 *
 * En caso de error a la hora de ir a buscar una nueva credencial
 * está limpia el sessionStorage y redirige a una página de error.
 */
axiosInstance.interceptors.response.use(
	(response) => response,
	async (error): Promise<AxiosError> => {
		const config: any = error?.config;

		if (error?.response?.status === 401) {
			const data: ProcessedData<string> | ErrorMessage = await generateToken(generateBackendUrl().GET_TOKEN);
			if (data?.code === 200) {
				const token: string = (data as ProcessedData<string>).data;
				sessionStorage.setItem('jwt', token);
				config.headers.Authorization = `Bearer ${token}`;
				return axios(config);
			}

			forceLogout();
		}

		return Promise.reject(error);
	},
);

export default axiosInstance;
