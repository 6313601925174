import { DATAFOLDER } from '../constants/routes.constants';
import type { Contacto } from '../interfaces/model/IContancto';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_CONTACTO: `${DATAFOLDER}/data/contacto.json`,
});

export const getContacto = async () => callGetExternalApi<Contacto>({ url: getBackendUrl().GET_CONTACTO });
