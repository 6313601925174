import { useEffect, useState, type ReactElement } from 'react';

import { HOMEPAGE } from '../../../constants/routes.constants';
import type { IPrograma } from '../../../interfaces/model/IPrograma';

import { getProgramaSesion } from '../../../services/programa.service';

import Program from '../../../components/Program';

import { groupProgram } from '../../../utils/arrayUtils';
import { convertDateFormat } from '../../../utils/dateUtils';

const Programa = (): ReactElement => {
	const [programa, setPrograma] = useState<IPrograma[]>([]);

	const loadPrograma = async () => {
		const { data } = await getProgramaSesion();
		setPrograma(data);
	};

	useEffect(() => {
		loadPrograma();
	}, []);

	return (
		<>
			{groupProgram(programa).map(({ dia, data }) => (
				<article key={dia} className="c-jb-program">
					<h3 className="c-jb-program__header">{convertDateFormat(dia)}</h3>
					<div className="c-jb-program__wrap">
						{data.map(({ id, color, horario, titulo, subtitulo, participantes }: any) => (
							<Program
								key={id}
								color={color}
								horario={horario}
								titulo={titulo}
								subtitulo={subtitulo}
								participantes={participantes}
							/>
						))}
					</div>
				</article>
			))}
			<a
				download
				className="u-mt-24 c-jb-home__cards-link"
				href={`${HOMEPAGE}/documentos/programa-sesion-plenaria-2024.pdf`}
				target="_blank"
				rel="noopener noreferrer"
			>
				Descargar programa
			</a>
		</>
	);
};

export default Programa;
