import React from 'react';
import { classNames } from 'primereact/utils';

import AUIButton from '@ancert/aui-button';

type BackProps = {
	readonly className?: string;
	readonly onBackClick: () => void;
};

const Back = ({ className, onBackClick }: BackProps): React.ReactElement => {
	const classNameBack = classNames('c-jb-back', className);

	return (
		<div className={classNameBack}>
			<AUIButton
				type="button"
				className="c-jb-back__btn"
				label="Volver"
				size="lg"
				icon="arrowLeft"
				iconPos="left"
				mode="ghost"
				onClick={onBackClick}
			/>
		</div>
	);
};

export default Back;
