import { useEffect, useState } from 'react';

import { type IFormAcompanante, ACTIVIDADES, CENA, EXCURSIONES, EXCURSIONES_ACOMPANIANTE } from './form';

export const useCalculate = (options: any) => {
	const [price, setPrice] = useState(0);

	useEffect(() => {
		const actividades: string = options[0];
		const cena: string[] = options[1];
		const excursiones: string = options[2];
		const acompanantes: IFormAcompanante[] = options[3];

		let total = 0;

		if (actividades) {
			const actividad = ACTIVIDADES.find((item) => item.value === actividades);
			if (actividad) {
				const { price } = actividad;
				total += price;
			}
		}

		cena.forEach((valor: string) => {
			const cena = CENA.find((item) => item.value === valor);
			if (cena) {
				const { price } = cena;
				total += price;
			}
		});

		if (excursiones === 'Si') {
			const { price } = EXCURSIONES;
			total += price;
		}

		acompanantes.forEach(({ excursiones }) => {
			excursiones.forEach((valor: string) => {
				const excursion = EXCURSIONES_ACOMPANIANTE.find((item) => item.value === valor);
				if (excursion) {
					const { price } = excursion;
					total += price;
				}
			});
		});

		setPrice(total);
	}, [options]);

	return price;
};
