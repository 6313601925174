import { DATAFOLDER } from '../constants/routes.constants';
import type { IHotel } from '../interfaces/model/IHotel';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_HOTEL: `${DATAFOLDER}/data/hotel.json`,
});

export const getHotel = async () => callGetExternalApi<IHotel>({ url: getBackendUrl().GET_HOTEL });
