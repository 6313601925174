import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../constants';
import { HOMEPAGE } from '../../constants/routes.constants';

export default function Footer(): React.ReactElement {
	return (
		<footer className="c-jb-footer">
			<ul className="c-jb-footer__list">
				<li className="c-jb-footer__item">
					<NavLink className="c-jb-footer__link" to={ROUTES.POLITICA_PRIVACIDAD}>
						Política de privacidad
					</NavLink>
				</li>
				<li className="c-jb-footer__item">
					<NavLink className="c-jb-footer__link" to={ROUTES.DATOS_CONTACTO}>
						Datos de contacto
					</NavLink>
				</li>
				<li className="c-jb-footer__item">
					<NavLink className="c-jb-footer__link" to={ROUTES.AVISO_LEGAL}>
						Aviso legal
					</NavLink>
				</li>
				<li className="c-jb-footer__item">
					<NavLink className="c-jb-footer__link" to={ROUTES.MAPA_WEB}>
						Mapa web
					</NavLink>
				</li>
				<li className="c-jb-footer__item">
					<NavLink className="c-jb-footer__link" to={ROUTES.POLITICA_COOKIES}>
						Política de cookies
					</NavLink>
				</li>
			</ul>
		</footer>
	);
}
