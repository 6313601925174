import { type ReactElement } from 'react';
import hero from '../../assets/images/hero.png';
import Cards from './Cards';
import Actos from './Actos';

const Home = (): ReactElement => (
	<div className="c-jb-home">
		<img src={hero} alt="" className="c-jb-home__hero" />
		<Cards />
		<Actos />
	</div>
);

export default Home;
