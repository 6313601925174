import { AUINotificationContext } from '@ancert/aui-notification-context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Layout } from './containers';
import Routes from './routes';

import { HOMEPAGE, ROUTES } from './constants/routes.constants';

import '@ancert/aui-styles/prime.scss';
import '@ancert/aui-styles/styles.scss';

import './assets/themes/theme.css';
import './styles/styles.scss';

import KlaroCookies from './components/KlaroCookies';

import { TRACKING_ID } from './constants/config.constants';
import { useEffect, useState } from 'react';
import { getBlock } from './services/block.service';
import type { IBlock } from './interfaces/model/IBlock';
import Block from './components/Block';

ReactGA.initialize(TRACKING_ID);
function App() {
	const [initialData, setInitialData] = useState<IBlock>();

	const getData = async () => {
		const { data } = await getBlock();
		setInitialData(data);
	};

	useEffect(() => {
		getData();
	}, []);

	return !initialData || initialData?.block ? (
		<Block titulo={initialData?.titulo} content={initialData?.content} />
	) : (
		<BrowserRouter basename={HOMEPAGE}>
			<Switch>
				<Route
					exact
					path={Object.keys(ROUTES).map((key: string) => ROUTES[key])}
					component={() => (
						<AUINotificationContext>
							<KlaroCookies>
								<Layout>
									<Routes />
								</Layout>
							</KlaroCookies>
						</AUINotificationContext>
					)}
				/>
				<Route component={() => <></>} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
