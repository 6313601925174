/* eslint-disable react/jsx-child-element-spacing */
import { useEffect, useRef, useState, type ReactElement } from 'react';
import { useFieldArray, useForm, type SubmitHandler } from 'react-hook-form';

import AUIButton from '@ancert/aui-button';
import AUIDropdownField from '@ancert/aui-dropdown-field';
import AUIInputCheckboxField from '@ancert/aui-input-checkbox-field';
import AUIInputRadioButtonField from '@ancert/aui-input-radiobutton-field';
import AUIInputTextField from '@ancert/aui-input-text-field';
import { useNotification } from '@ancert/aui-notification-context';

import type { ISelect } from '../../interfaces/AUI/ISelect';
import type { IInscripcionResponse } from '../../interfaces/services/IInscricpion';
import {
	ACTIVIDADES,
	CENA,
	defaultAcompananteValues,
	defaultInscripcionValues,
	EXCURSIONES,
	EXCURSIONES_ACOMPANIANTE,
	resolver,
	type IFormInscripcion,
} from './form';

import { APP, SITE_KEY } from '../../constants/config.constants';
import { HOMEPAGE, ROUTES } from '../../constants/routes.constants';

import { useCalculate } from './useCalculate';
import { useSave } from './useSave';

import InputCheckboxPrice from '../InputCheckboxPrice';
import InputRadioButtonPrice from '../InputRadioButtonPrice';
import InscripcionOk from '../InscripcionOk';
import LoadingModal from '../LoadingModal';

import { formatNumberDecimals } from '../../utils/numberUtils';

import { getPaises } from '../../services/paises.service';

import ReCAPTCHA from 'react-google-recaptcha';
import { useSessionStorage } from '../../hook/useSessionStorage';
import InputRadioButtonChoice from '../InputRadioButtonChoice';

type IRegistered = {
	code: string;
	price: number;
	fullName: string;
	sended: boolean;
};

const reduceFuncion = (acc: any, cur: any, index: number) => {
	if (cur) {
		return {
			...acc,
			...{
				[`acompanantes.${index}.nombre`]: cur.nombre,
				[`acompanantes.${index}.primerApellido`]: cur.primerApellido,
			},
		};
	}

	return acc;
};

const mappingFormStateErrors = (errors: any) =>
	errors?.acompanantes ? errors.acompanantes.reduce(reduceFuncion, {}) : {};

const InscripcionForm = (): ReactElement => {
	const ref = useRef<any>();
	const { openNotification } = useNotification();
	const [sended, setSended] = useState(false);
	const [captchaCode, setCaptchaCode] = useState<string>('');
	const [paisesData, setPaisesData] = useState<ISelect[]>();
	const [registered, setRegistered] = useSessionStorage<IRegistered>('registered', {
		code: '',
		price: 0,
		fullName: '',
		sended: false,
	});

	const { control, formState, watch, handleSubmit, setValue, getValues } = useForm<IFormInscripcion>({
		mode: 'onChange',
		defaultValues: defaultInscripcionValues,
		resolver,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'acompanantes',
	});

	const loadData = async () => {
		const { data } = await getPaises();
		setPaisesData(data);
	};

	const onAddAcompanante = () => {
		append(defaultAcompananteValues);
	};

	const onRemoveAcompanante = (index: number) => {
		remove(index);
	};

	const saved = ({ codigo }: IInscripcionResponse) => {
		ref.current.close();
		setRegistered({
			code: codigo,
			price,
			fullName: `${watch('nombre')} ${watch('primerApellido')} ${watch('segundoApellido')}`,
			sended: false,
		});
		setSended(true);
	};

	const error = () => {
		ref.current.close();
		openNotification({
			title: 'Error',
			content: 'Error al realizar la inscripción',
			type: 'error',
			iconColor: 'red',
			centerButtons: true,
		});
	};

	const { save } = useSave({ saved, error });

	const onSubmit: SubmitHandler<IFormInscripcion> = (data) => {
		ref.current.open();
		const { aceptar, ...restData } = data;
		const excursiones = data.excursiones === 'Si' ? ['barco'] : [];
		const saveData = {
			aplicacion: APP,
			formulario: { ...restData, total: price, captchaCode, excursiones },
		};
		save(saveData);
	};

	const watchFields = watch(['actividades', 'cena', 'excursiones', 'acompanantes']);

	const price = useCalculate(watchFields);

	const changeAceptarCheckbox = () => {
		const aceptar = getValues('aceptar') as string[];
		setValue('aceptar', aceptar.length > 0 ? [] : ['si'], { shouldValidate: true });
	};

	useEffect(() => {
		loadData();
	}, []);

	if (sended) {
		return <InscripcionOk fullName={registered.fullName} price={registered.price} code={registered.code} />;
	}

	return (
		<>
			<section className="c-jb-form">
				<header className="c-jb-form__header">
					<h2 className="c-jb-heading">Formulario de inscripción</h2>
					<p>
						El importe deberá abonarse mediante transferencia bancaria con los datos indicados al completar
						la inscripción.
					</p>
				</header>
				<form className="[ c-jb-form__form ] [ u-mt-16 ]" onSubmit={handleSubmit(onSubmit)}>
					<div>
						<fieldset className="c-jb-form-congressman">
							<legend className="c-jb-form-congressman__legend">
								<span className="c-jb-form-congressman__tit">Datos del congresista</span>
							</legend>
							<div className="o-grid">
								<div className="o-grid__row">
									<div className="o-grid__col-12">
										<div className="[ c-jb-form-congressman__activities ] [ u-mt-8 ]">
											<AUIInputRadioButtonField
												required
												name="actividades"
												labelField=""
												columns={1}
												controlRef={control}
												errors={{}}
												classNameField="c-jb-field--no-feedback"
												radiobuttonArray={ACTIVIDADES.map(
													({ value, label, price, explanation }) => ({
														value,
														label: (
															<div>{`${label} (${price}€)${explanation ? ` ${explanation}` : ''}`}</div>
														),
													}),
												)}
												radioSize="lg"
											/>
										</div>
									</div>
								</div>

								<div className="o-grid__row u-mt-16">
									<div className="o-grid__col-4">
										<AUIInputTextField
											required
											name="nombre"
											label="Nombre"
											keyfilter={/^[a-zA-ZñÑ_ ]*$/}
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-4">
										<AUIInputTextField
											required
											name="primerApellido"
											label="Primer apellido"
											keyfilter={/^[a-zA-ZñÑ_ ]*$/}
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-4">
										<AUIInputTextField
											name="segundoApellido"
											label="Segundo apellido"
											keyfilter={/^[a-zA-ZñÑ_ ]*$/}
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
								</div>
								<div className="o-grid__row">
									<div className="o-grid__col-6">
										<AUIInputTextField
											required
											name="direccion"
											label="Dirección"
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-4">
										<AUIInputTextField
											required
											name="localidad"
											label="Localidad"
											keyfilter={/^[a-zA-ZñÑ_ ]*$/}
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-2">
										<AUIDropdownField
											required
											name="pais"
											label="País"
											controlRef={control}
											errors={formState.errors}
											options={paisesData}
										/>
									</div>
								</div>
								<div className="o-grid__row">
									<div className="o-grid__col-5">
										<AUIInputTextField
											required
											name="email"
											label="E-mail"
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-5">
										<AUIInputTextField
											required
											name="reEmail"
											label="Repetir e-mail"
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
									<div className="o-grid__col-2">
										<AUIInputTextField
											required
											name="telefono"
											label="Teléfono"
											keyfilter={/^[+\d]+$/}
											controlRef={control}
											errors={formState.errors}
										/>
									</div>
								</div>
							</div>
						</fieldset>
						{fields.map(({ id }, index) => (
							<fieldset
								key={id}
								className="[ c-jb-form-congressman  c-jb-form-congressman--companion ] [ u-mt-24 ]"
							>
								<legend className="c-jb-form-congressman__legend">
									<span className="c-jb-form-congressman__tit">
										Datos del acompañante {index + 1}
										<span className="c-jb-form-congressman__tit-action">
											<AUIButton
												type="button"
												mode="ghost"
												size="sm"
												icon="close"
												onClick={() => onRemoveAcompanante(index)}
											/>
										</span>
									</span>
								</legend>
								<div className="o-grid">
									<div className="o-grid__row">
										<div className="o-grid__col-4">
											<AUIInputTextField
												required
												name={`acompanantes.${index}.nombre`}
												label="Nombre"
												keyfilter={/^[a-zA-ZñÑ_ ]*$/}
												controlRef={control}
												errors={mappingFormStateErrors(formState.errors)}
											/>
										</div>
										<div className="o-grid__col-4">
											<AUIInputTextField
												required
												name={`acompanantes.${index}.primerApellido`}
												label="Primer apellido"
												keyfilter={/^[a-zA-ZñÑ_ ]*$/}
												controlRef={control}
												errors={mappingFormStateErrors(formState.errors)}
											/>
										</div>
										<div className="o-grid__col-4">
											<AUIInputTextField
												name={`acompanantes.${index}.segundoApellido`}
												label="Segundo apellido"
												keyfilter={/^[a-zA-ZñÑ_ ]*$/}
												controlRef={control}
												errors={formState.errors}
											/>
										</div>
									</div>
								</div>
							</fieldset>
						))}

						{fields.length === 0 && (
							<p className="[ c-jb-form-congressman c-jb-form-congressman-no-companion ] [ u-mt-24 ]">
								<i>No ha añadido ningún acompañante</i>
							</p>
						)}

						<AUIButton
							type="button"
							label="Añadir acompañante"
							size="lg"
							mode="secondary"
							icon="add"
							iconPos="left"
							className="u-mt-24"
							onClick={onAddAcompanante}
						/>
					</div>
					<section className="c-jb-form-column c-jb-form__cart">
						<section className="c-jb-form__cart-congressman">
							<h3 className="c-jb-form__cart-tit">Actividades del congresista</h3>
							<InputRadioButtonPrice
								name="actividades"
								label=""
								className="c-jb-checkbox-price--bolder"
								radiobuttonArray={ACTIVIDADES}
								controlRef={control}
								errors={{}}
							/>

							<InputCheckboxPrice
								name="cena"
								label="Cena de gala"
								checkboxArray={CENA}
								controlRef={control}
								errors={formState.errors}
							/>

							<InputRadioButtonChoice
								name="excursiones"
								label={EXCURSIONES.label}
								price={EXCURSIONES.price}
								radiobuttonArray={EXCURSIONES.radioOptions}
								controlRef={control}
								errors={{}}
							/>
						</section>
						{fields.map((item, index) => (
							<section
								key={item.id}
								className="c-jb-form__cart-congressman c-jb-form__cart-congressman--companion"
							>
								<header className="c-jb-form__cart-header">
									<h3 className="c-jb-form__cart-tit">Actividades del acompañante {index + 1}</h3>
								</header>
								<InputCheckboxPrice
									name={`acompanantes.${index}.excursiones`}
									label="Cena de gala"
									checkboxArray={[EXCURSIONES_ACOMPANIANTE[1]]}
									controlRef={control}
									errors={formState.errors}
								/>
								<InputCheckboxPrice
									name={`acompanantes.${index}.excursiones`}
									label="Excursión"
									checkboxArray={[EXCURSIONES_ACOMPANIANTE[0]]}
									controlRef={control}
									errors={formState.errors}
								/>
							</section>
						))}

						{fields.length === 0 && (
							<div className="c-jb-form__cart-congressman c-jb-form__cart-congressman--no-companion">
								<p>
									<i>No ha añadido ningún acompañante</i>
								</p>
								<button type="button" className="c-jb-form__cart-add" onClick={onAddAcompanante}>
									Añadir acompañante
								</button>
							</div>
						)}

						<aside className="c-jb-form__cart-congressman c-jb-form__cart-congressman--conditions">
							<div className="c-jb-form__cart-congressman--checkbox">
								<AUIInputCheckboxField
									required
									name="aceptar"
									labelField=""
									columns={1}
									controlRef={control}
									errors={{}}
									classNameField="c-jb-field--no-feedback"
									checkboxArray={[{ value: 'si', label: '' }]}
									checkSize="lg"
								/>
								<p className="c-jb-form__cart-congressman--checkboxLabel">
									<button type="button" onClick={changeAceptarCheckbox}>
										He leído y acepto la
									</button>{' '}
									<a
										className="c-jb-form__cart-congressman--link"
										href={`${HOMEPAGE}${ROUTES.POLITICA_PRIVACIDAD}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										Política de Privacidad
									</a>
								</p>
							</div>
							<ReCAPTCHA sitekey={SITE_KEY} onChange={(code) => setCaptchaCode(code ?? '')} />
						</aside>

						<footer className="c-jb-form__cart-footer">
							<div className="c-jb-form__cart-total">
								<p>
									Total <span className="c-jb-form__cart-vat">{fields.length + 1} asistentes</span>
								</p>
								<p className="c-jb-form__cart-total-count">
									{formatNumberDecimals(price)} €
									<span className="c-jb-form__cart-vat">Impuestos incluidos</span>
								</p>
							</div>

							<p className="[ c-jb-form__cart-reminder ] [ u-mt-24 ]">
								<span className="c-jb-form__cart-reminder--bold">RECORDATORIO</span>: El importe de la
								cuota debe llegar íntegro a la organización.{' '}
								<span className="c-jb-form__cart-reminder--semi-bold">
									La transferencia debe recibirse libre de gastos bancarios
								</span>
								. Muchas gracias.
							</p>
							<AUIButton
								type="submit"
								label="Realizar inscripción"
								size="lg"
								disabled={!(formState.isValid && captchaCode)}
								className="[ c-jb-form__cart-cta ] [ u-mt-24 ]"
							/>
						</footer>
					</section>
				</form>
			</section>
			<LoadingModal ref={ref} message="Guardando inscripción" />
		</>
	);
};

export default InscripcionForm;
