import { DATAFOLDER } from '../constants/routes.constants';
import type { ITitle } from '../interfaces/model/ITitle';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_PAGE_TITLE: `${DATAFOLDER}/data/title.json`,
	GET_SESION_PLENARIA_TITLE: `${DATAFOLDER}/data/sesion-plenaria/title.json`,
	GET_JORNADA_NOTARIAL_TITLE: `${DATAFOLDER}/data/jornada-notarial/title.json`,
});

const getHomeTitle = async () => callGetExternalApi<ITitle>({ url: getBackendUrl().GET_PAGE_TITLE });

const getSesionPenariaTitle = async () =>
	callGetExternalApi<ITitle>({ url: getBackendUrl().GET_SESION_PLENARIA_TITLE });

const getJornadaNotarialTitle = async () =>
	callGetExternalApi<ITitle>({ url: getBackendUrl().GET_JORNADA_NOTARIAL_TITLE });

export { getHomeTitle, getSesionPenariaTitle, getJornadaNotarialTitle };
