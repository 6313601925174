import { useEffect, useState, type ReactElement } from 'react';
import parse from 'html-react-parser';

import type { IVisado } from '../../../interfaces/model/IVisado';

import { getInfoVisados } from '../../../services/infoInteres.service';

const Visados = (): ReactElement => {
	const [visadosData, setVisadosData] = useState<IVisado>();

	const loadData = async () => {
		const { data } = await getInfoVisados();
		setVisadosData(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	return visadosData ? (
		<>
			<h2 className="c-jb-heading">{parse(visadosData.titulo)}</h2>
			<div className="[ u-mt-16 ]">{parse(visadosData.content)}</div>
		</>
	) : (
		<></>
	);
};

export default Visados;
