import { useEffect, useState, type ReactElement } from 'react';
import logoEncuentros3 from '../../assets/images/logo-cgn.png';
import logoCJI from '../../assets/images/logo-cji.png';
import logoCplp from '../../assets/images/logo-cplp.png';
import logoCumbreJudicial from '../../assets/images/logo-cumbre-judicial.png';
import logoAAMP from '../../assets/images/logo-encuentros-2.png';
import type { IActo } from '../../interfaces/model/IActo';
import { getHomeActs } from '../../services/home.service';
import Acto from './Cards/Acto';

const Actos = (): ReactElement => {
	const [actos, setActos] = useState<IActo | undefined>(undefined);

	const loadData = async () => {
		const { data } = await getHomeActs();
		setActos(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<section className="c-jb-home__actos">
			<h2 className="c-jb-home__actos-tit">ACTOS EN PARALELO</h2>
			{actos && (
				<ul className="c-jb-home__actos-cards">
					<li className="c-jb-home__actos-item">
						<Acto descripcion={actos.acto1} logos={[logoCumbreJudicial]} />
					</li>
					<li className="c-jb-home__actos-item">
						<Acto descripcion={actos.acto2} logos={[logoCJI]} />
					</li>
					<li className="c-jb-home__actos-item">
						<Acto descripcion={actos.acto3} logos={[logoCplp, logoAAMP]} />
					</li>
					<li className="c-jb-home__actos-item">
						<Acto descripcion={actos.acto4} logos={[logoEncuentros3]} />
					</li>
				</ul>
			)}
		</section>
	);
};

export default Actos;
