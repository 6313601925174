import { useEffect, useState } from 'react';
import { ReactComponent as HambButton } from '../../assets/images/hamb.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames } from 'primereact/utils';

import { ROUTES, MENU } from '../../constants';
import { HOTEL_URL } from '../../constants/config.constants';

const findMenuByRoute = (route: string): string => {
	const foundItem = Object.values(MENU).find((item) => item.route === route);
	return foundItem ? foundItem.menu : '';
};

type ISubMenuOption = {
	route: string;
	option: string;
};

const SESION_PLENARIA_OPTIONS: ISubMenuOption[] = [
	// { route: ROUTES.SESION_PLENARIA_PRESENTACION, option: 'Presentación' },
	{ route: ROUTES.SESION_PLENARIA_PROGRAMA, option: 'Programa' },
	{ route: ROUTES.SESION_PLENARIA_INSCRIPCION, option: 'Inscripción' },
	{ route: ROUTES.SESION_PLENARIA_HOTEL, option: 'Alojamiento' },
];

const JORNADA_NOTARIAL_OPTIONS: ISubMenuOption[] = [
	// { route: ROUTES.JORNADA_NOTARIAL_PRESENTACION, option: 'Presentación' },
	{ route: ROUTES.JORNADA_NOTARIAL_PROGRAMA, option: 'Programa' },
	{ route: ROUTES.JORNADA_NOTARIAL_INSCRIPCION, option: 'Inscripción' },
	{ route: ROUTES.JORNADA_NOTARIAL_HOTEL, option: 'Alojamiento' },
];

const INFO_OPTIONS: ISubMenuOption[] = [
	{ route: ROUTES.INFO_VISADOS, option: 'Información visados' },
	{ route: ROUTES.INFO_VESTIMENTA, option: 'Código de vestimenta' },
];

type SubMenuProps = {
	readonly options: ISubMenuOption[];
};

/**
 * Componente para generar submenu.
 */
const SubMenu = ({ options }: SubMenuProps) => (
	<ul className="c-jb-nav__sublist">
		{options.map(({ route, option }) => (
			<li key={route}>
				<NavLink strict to={route} className="c-jb-nav__sublink">
					{option}
				</NavLink>
			</li>
		))}
	</ul>
);

export default function Header(): React.ReactElement {
	const location = useLocation();

	const [isOpen, setIsOpen] = useState(false);
	const [pathname, setPathname] = useState('');

	const classNameNav = classNames('c-jb-nav', {
		['c-jb-nav--open']: isOpen,
	});

	const onClick = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		setPathname(location.pathname);
	}, [location]);

	return (
		<nav className={classNameNav}>
			<div className="c-jb-nav__btn-group">
				<button aria-label="menu" type="button" className="c-jb-nav__btn" onClick={onClick}>
					<HambButton />
				</button>
				<p className="c-jb-nav__location">{findMenuByRoute(pathname)}</p>
			</div>
			<ul className="c-jb-nav__list">
				<li className="c-jb-nav__item">
					<NavLink
						exact
						to={ROUTES.HOME}
						className="c-jb-nav__link"
						activeClassName="c-jb-nav__link--active"
						onClick={() => setIsOpen(false)}
					>
						{MENU.INICIO.menu}
					</NavLink>
				</li>
				<li className="c-jb-nav__item">
					<NavLink
						exact
						to={ROUTES.PRESENTACION}
						className="c-jb-nav__link"
						activeClassName="c-jb-nav__link--active"
						onClick={() => setIsOpen(false)}
					>
						{MENU.PRESENTACION.menu}
					</NavLink>
				</li>
				<li className="c-jb-nav__item c-jb-nav__item--dropdown">
					<NavLink
						strict
						to={ROUTES.SESION_PLENARIA}
						className="c-jb-nav__link"
						activeClassName="c-jb-nav__link--active"
						onClick={() => setIsOpen(false)}
					>
						{MENU.SESION_PLENARIA.menu}
					</NavLink>
					<SubMenu options={SESION_PLENARIA_OPTIONS} />
				</li>
				<li className="c-jb-nav__item c-jb-nav__item--dropdown">
					<NavLink
						strict
						to={ROUTES.JORNADA_NOTARIAL}
						className="c-jb-nav__link"
						activeClassName="c-jb-nav__link--active"
						onClick={() => setIsOpen(false)}
					>
						{MENU.JORNADA_NOTARIAL.menu}
					</NavLink>
					<SubMenu options={JORNADA_NOTARIAL_OPTIONS} />
				</li>
				<li className="c-jb-nav__item c-jb-nav__item--dropdown">
					<NavLink
						strict
						to={ROUTES.INFO}
						className="c-jb-nav__link"
						activeClassName="c-jb-nav__link--active"
						onClick={() => setIsOpen(false)}
					>
						{MENU.INFO.menu}
					</NavLink>
					<SubMenu options={INFO_OPTIONS} />
				</li>
			</ul>
		</nav>
	);
}
