import { classNames } from 'primereact/utils';
import React from 'react';

import AUIField from '@ancert/aui-field';
import AUIInputRadioButtonField from '@ancert/aui-input-radiobutton-field';

import type { ISelectPrice } from '../../interfaces/AUI/ISelect';

import { formatNumberDecimals } from '../../utils/numberUtils';

type InputRadioButtonPriceProps = {
	readonly name: string;
	readonly label: string;
	readonly className?: string;
	readonly radiobuttonArray: ISelectPrice[];
	readonly controlRef: any;
	readonly errors: any;
};

const InputRadioButtonPrice = ({
	name,
	label,
	className,
	radiobuttonArray,
	controlRef,
	errors,
}: InputRadioButtonPriceProps): React.ReactElement => {
	const classNameRadioButtonPrice = classNames('c-jb-checkbox-price', className);

	return (
		<div className={classNameRadioButtonPrice}>
			<AUIField condensed label={label} className="c-jb-checkbox-price__label">
				<ul className="c-jb-checkbox-price__list">
					{radiobuttonArray.map(({ label, value, price }) => (
						<li key={value} className="c-jb-checkbox-price__item">
							<AUIInputRadioButtonField
								required
								classNameField="c-jb-field--no-feedback"
								name={name}
								columns={1}
								controlRef={controlRef}
								errors={{}}
								radiobuttonArray={[{ label, value }]}
								radioSize="lg"
							/>
							<p className="c-jb-checkbox-price__price">
								<strong>{price > 0 ? `${formatNumberDecimals(price)} €` : 'Gratis'}</strong>
							</p>
						</li>
					))}
				</ul>
			</AUIField>
		</div>
	);
};

export default InputRadioButtonPrice;
