import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { type ErrorMessage, type ProcessedData } from '../interfaces/utils/axios.interface';

const jwtAxios = axios.create({});

/**
 * Procesa un error en la llamada, en caso de ser un error
 * no controlado en el back genera un error 400 - Axios - Bad request.
 */
const processError = (code: number | undefined): ErrorMessage => ({
	code: code ?? 400,
	message: 'Axios - Unexpected Error',
});

/**
 * Procesa un llamada correcta.
 */
const processResponse = <T>(response: AxiosResponse<T>): ProcessedData<T> | ErrorMessage => {
	if (response.status >= 200 && response.status < 300) {
		return { code: response.status, data: response.data };
	}
	return processError(response.status);
};

/**
 * Procesa un llamada para buscar un token.
 */
const generateToken = async (url: string): Promise<ProcessedData<string> | ErrorMessage> => {
	try {
		const callResponse = await jwtAxios.get(url);
		return processResponse(callResponse);
	} catch (err: unknown) {
		return processError((err as AxiosError).status);
	}
};

export default generateToken;
