import { ROUTES, MENU } from '../../constants';

import { HOMEPAGE } from '../../constants/routes.constants';

import type { MapData } from '../../interfaces/AUI/IMapWeb';

export const map: MapData[][] = [
	[
		{
			title: MENU.INICIO.menu,
			route: ROUTES.HOME,
			subroutes: [],
			external: false,
			download: false,
		},
		{
			title: MENU.PRESENTACION.menu,
			route: ROUTES.PRESENTACION,
			subroutes: [],
			external: false,
			download: false,
		},
	],
	[
		{
			title: MENU.SESION_PLENARIA.menu,
			subroutes: [
				{
					title: 'Programa',
					route: ROUTES.SESION_PLENARIA_PROGRAMA,
					external: false,
					download: false,
				},
				{
					title: 'Inscripción',
					route: ROUTES.SESION_PLENARIA_INSCRIPCION,
					external: false,
					download: false,
				},
				{
					title: 'Alojamiento',
					route: ROUTES.SESION_PLENARIA_HOTEL,
					external: false,
					download: false,
				},
			],
		},
		{
			title: MENU.JORNADA_NOTARIAL.menu,
			subroutes: [
				{
					title: 'Programa',
					route: ROUTES.JORNADA_NOTARIAL_PROGRAMA,
					external: false,
					download: false,
				},
				{
					title: 'Inscripción',
					route: ROUTES.JORNADA_NOTARIAL_INSCRIPCION,
					external: false,
					download: false,
				},
				{
					title: 'Alojamiento',
					route: ROUTES.JORNADA_NOTARIAL_HOTEL,
					external: false,
					download: false,
				},
			],
		},
	],
	[
		{
			title: 'Política de privacidad',
			route: `${HOMEPAGE}/pdf/politica-privacidad.pdf`,
			subroutes: [],
			external: true,
			download: true,
		},
		{
			title: 'Datos de contacto',
			route: ROUTES.DATOS_CONTACTO,
			subroutes: [],
			external: false,
			download: false,
		},
		{
			title: 'Aviso legal',
			route: ROUTES.AVISO_LEGAL,
			subroutes: [],
			external: false,
			download: false,
		},
		{
			title: 'Política de cookies',
			route: `${HOMEPAGE}/pdf/politica-cookies.pdf`,
			subroutes: [],
			external: true,
			download: true,
		},
	],
	[
		{
			title: MENU.INFO.menu,
			route: ROUTES.INFO,
			subroutes: [
				{
					title: MENU.INFO_VISADOS.menu,
					route: ROUTES.INFO_VISADOS,
					external: false,
					download: false,
				},
				{
					title: MENU.INFO_VESTIMENTA.menu,
					route: ROUTES.INFO_VESTIMENTA,
					external: false,
					download: false,
				},
			],
			external: false,
			download: false,
		},
	],
];
