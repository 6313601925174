import { DATAFOLDER } from '../constants/routes.constants';
import type { IPresentacion } from '../interfaces/model/IPrograma';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_POLITICA_PRIVACIDAD: `${DATAFOLDER}/data/politica-privacidad.json`,
	GET_POLITICA_COOKIES: `${DATAFOLDER}/data/politica-cookies.json`,
	GET_AVISO_LEGAL: `${DATAFOLDER}/data/aviso-legal.json`,
});

export const getPoliticaPrivacidad = async () =>
	callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_POLITICA_PRIVACIDAD });

export const getPoliticaCookies = async () =>
	callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_POLITICA_COOKIES });

export const getAvisoLegal = async () => callGetExternalApi<IPresentacion>({ url: getBackendUrl().GET_AVISO_LEGAL });
