import type { IPrograma } from '../interfaces/model/IPrograma';

export const groupProgram = (programa: IPrograma[]): any[] =>
	programa.reduce<any[]>((acc, current) => {
		const foundItem = acc.find((it) => it.dia === current.dia);

		if (foundItem) {
			foundItem.data = foundItem.data ? [...foundItem.data, { ...current }] : [{ ...current }];
		} else {
			acc.push({
				dia: current.dia,
				data: [{ ...current }],
			});
		}
		return acc;
	}, []);
