/* eslint-disable react/jsx-child-element-spacing */
import { useEffect, useState, type ReactElement } from 'react';
import parse from 'html-react-parser';
import YouTube from 'react-youtube';

import type { IHotel } from '../../../interfaces/model/IHotel';

import { getHotel } from '../../../services/hotel.service';

const videoProps = {
	height: 'auto',
	width: '100%',
};

const Hotel = (): ReactElement => {
	const [hotelData, setHotelData] = useState<IHotel>();

	const loadData = async () => {
		const { data } = await getHotel();
		setHotelData(data);
	};

	useEffect(() => {
		loadData();
	}, []);

	return hotelData ? (
		<>
			<h2 className="c-jb-heading">{parse(hotelData.titulo)}</h2>
			<div className="[ u-mt-16 ]">{parse(hotelData.content)}</div>
			<figure className="[ c-jb-video-wrap c-jb-video-wrap--half ] [ u-mt-16 ]">
				<YouTube className="c-jb-video" videoId={hotelData.videoId} opts={videoProps} />
				<figcaption className="c-jb-video__caption c-jb-video--half">{hotelData.figCaption}</figcaption>
			</figure>
		</>
	) : (
		<></>
	);
};

export default Hotel;
