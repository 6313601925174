const tokenService: string | undefined = process.env.REACT_APP_TOKEN_SERVICE;
const tokenKey: string | undefined = process.env.REACT_APP_TOKEN_KEY;
const tokenExpire: string | undefined = process.env.REACT_APP_TOKEN_EXPIRE;

const baseURL = 'http://localhost:9000/api';

/**
 * Listado de endpoints del backend
 */
export default () => ({
	// Endpoint para gestionar el JWT
	GET_TOKEN: `${tokenService}?pubKey=${tokenKey}&exp=${tokenExpire}`,
	GET_LISTADO_NOTARIOS: `${baseURL}/notarios`,
	GET_LISTADO_NOTARIAS: `${baseURL}/notarias`,
});
