import { DATAFOLDER } from '../constants/routes.constants';
import type { IBlock } from '../interfaces/model/IBlock';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_BLOCK: `${DATAFOLDER}/data/block.json`,
});

export const getBlock = async () => callGetExternalApi<IBlock>({ url: getBackendUrl().GET_BLOCK });
