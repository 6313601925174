import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import Home from './pages/Home';
import Presentacion from './pages/Presentacion';
import SesionPlenaria from './pages/SesionPlenaria';
import JornadaNotarial from './pages/JornadaNotarial';
import DatosContacto from './pages/DatosContacto';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad';
import AvisoLegal from './pages/AvisoLegal';
import PoliticaCookies from './pages/PoliticaCookies';
import MapaWeb from './pages/MapaWeb';
import InformacionInteres from './pages/InformacionInteres';

import { ROUTES } from './constants/routes.constants';

export default function Routes() {
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	return (
		<Switch>
			<Route exact path={ROUTES.HOME} component={Home} />
			<Route exact path={ROUTES.PRESENTACION} component={Presentacion} />
			<Route strict path={ROUTES.SESION_PLENARIA} component={SesionPlenaria} />
			<Route strict path={ROUTES.JORNADA_NOTARIAL} component={JornadaNotarial} />
			<Route exact path={ROUTES.DATOS_CONTACTO} component={DatosContacto} />
			<Route exact path={ROUTES.POLITICA_PRIVACIDAD} component={PoliticaPrivacidad} />
			<Route exact path={ROUTES.AVISO_LEGAL} component={AvisoLegal} />
			<Route exact path={ROUTES.POLITICA_COOKIES} component={PoliticaCookies} />
			<Route exact path={ROUTES.MAPA_WEB} component={MapaWeb} />
			<Route strict path={ROUTES.INFO} component={InformacionInteres} />
		</Switch>
	);
}
