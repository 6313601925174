import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import AUIIcon from '@ancert/aui-icon';
import { classNames } from 'primereact/utils';
import { HOTEL_URL } from '../../constants/config.constants';

import { formatNumberDecimals } from '../../utils/numberUtils';
import { getInscripcionOk } from '../../services/inscripcion.service';

type InscripcionOkProps = {
	readonly price: number;
	readonly code: string;
	readonly fullName: string;
};

const InscripcionOk = ({ price, code, fullName }: InscripcionOkProps): React.ReactElement => {
	const [inscripcion, setInscripcion] = useState<any>(null);

	const loadData = async () => {
		const { data } = await getInscripcionOk();
		setInscripcion(data);
	};

	const onClickAlojamiento = () => {
		window.open(HOTEL_URL, '_blank', 'noopener,noreferrer');
	};

	const renderForm = () =>
		inscripcion?.data?.map(({ id, label, value }: any) => {
			const className = classNames('c-jb-form-ok__item', {
				['c-jb-form-ok__item--import']: id === 'importe',
			});

			let valueForm = value;

			if (id === 'importe') {
				valueForm = `<b>${formatNumberDecimals(price)} ${value}</b>`;
			}
			if (id === 'concepto') {
				valueForm = `<b>${code} ${fullName}</b>`;
			}

			return (
				<li key="id" className={className}>
					{label}: {parse(valueForm)}
				</li>
			);
		});

	useEffect(() => {
		loadData();
	}, []);

	return inscripcion ? (
		<div className="c-jb-form-ok">
			<AUIIcon icon="okCircleOutline" color="green" size="xxl" />
			<h2 className="[ c-jb-heading ] [ u-txt-center ]">{parse(inscripcion?.title)}</h2>
			{parse(inscripcion?.subtitle)}
			<ul className="c-jb-form-ok__list">{renderForm()}</ul>
			{parse(inscripcion?.mailText)}
			{parse(inscripcion?.hotelText)}
			{parse(inscripcion?.thanks)}
		</div>
	) : (
		<></>
	);
};

export default InscripcionOk;
