import type { ITab } from '../../../interfaces/AUI/ITabNavigation';

export const TABS: ITab[] = [
	{
		id: 1,
		header: 'Información visados',
	},
	{
		id: 2,
		header: 'Código de vestimenta',
	},
];
