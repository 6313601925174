import { classNames } from 'primereact/utils';
import React from 'react';

import AUIField from '@ancert/aui-field';
import AUIInputRadioButtonField from '@ancert/aui-input-radiobutton-field';

import type { ISelect } from '../../interfaces/AUI/ISelect';
import { formatNumberDecimals } from '../../utils/numberUtils';

type Props = {
	readonly name: string;
	readonly label: string;
	readonly className?: string;
	readonly price: number;
	readonly radiobuttonArray: ISelect[];
	readonly controlRef: any;
	readonly errors: any;
};

const InputRadioButtonChoice: React.FC<Props> = ({
	name,
	label,
	className,
	price,
	radiobuttonArray,
	controlRef,
	errors,
}) => {
	const classNameRadioButtonPrice = classNames('c-jb-checkbox-choice', className);
	return (
		<div className={classNameRadioButtonPrice}>
			<AUIField condensed label={label} className="c-jb-checkbox-choice__label">
				<ul className="c-jb-checkbox-choice__list">
					{radiobuttonArray.map(({ label, value }) => (
						<li key={value} className="c-jb-checkbox-choice__item">
							<AUIInputRadioButtonField
								required
								classNameField="c-jb-field--no-feedback"
								name={name}
								columns={1}
								controlRef={controlRef}
								errors={{}}
								radiobuttonArray={[{ label, value }]}
								radioSize="lg"
							/>
						</li>
					))}
				</ul>
			</AUIField>
			<p className="c-jb-checkbox-choice__price">
				<strong>{price > 0 ? `${formatNumberDecimals(price)} €` : 'Gratis'}</strong>
			</p>
		</div>
	);
};

export default InputRadioButtonChoice;
