import { type AxiosError, type AxiosResponse } from 'axios';
import axiosInstance from './axiosInterceptor';

import type { ErrorMessage, ExternalApiParams, IDefaultData, ProcessedData } from '../interfaces/utils/axios.interface';

/**
 * Si no se usa el interceptor para gestionar las
 * credenciales se ha de des comentar la creación
 * de la instancia de Axios.
 *
 * import axios from 'axios';
 * const axiosInstance = axios.create({
 *   baseURL: '/rest/'
 * });
 */

const processErrorMessage = (response: Record<string, any>): string => {
	// El back devuelve el error en un nodo message.
	if (response?.response?.data?.message) {
		return response?.response?.data?.message as string;
	}

	// El back devuelve el error en un nodo errorMessage.
	if (response?.response?.data?.errorMessage) {
		return response?.response?.data?.errorMessage as string;
	}

	// El back devuelve el error en el cuerpo directamente.
	if (response?.response?.data) {
		return response?.response?.data;
	}

	// Error no controlado.
	return 'Axios - Bad request';
};

/**
 * Procesa un error en la llamada, en caso de ser un error
 * no controlado en el back genera un error 400 - Axios - Bad request
 */
const processError = <T>(response: AxiosError<T | ErrorMessage>): any => {
	const status = response?.response?.status ?? 400;
	const errorMessage: string = processErrorMessage(response);
	return { status, data: null, error: errorMessage };
};

/**
 * Procesa un llamada correcta
 */
const processResponse = <T>(response: AxiosResponse<T> | AxiosError<T>): ProcessedData<T> => {
	const { status, data } = response as AxiosResponse<T>;
	if (status >= 200 && status < 300) return { code: status, data };
	return processError(response as AxiosError<T>);
};

/**
 * Realiza una llamada a la url informada y devuelve el resultado.
 * En caso de error se puede reintentar.
 */
const callGetExternalApi = async <T, D = IDefaultData>({
	url,
	headers,
	params,
	data,
	numTries,
}: ExternalApiParams<D>): Promise<ProcessedData<T>> => {
	try {
		const callResponse = await axiosInstance.get(url, {
			data,
			params,
			headers,
		});

		return processResponse(callResponse);
	} catch (err) {
		return numTries && numTries > 0
			? callGetExternalApi({ url, headers, params, data, numTries: numTries - 1 })
			: processError(err as AxiosError<T>);
	}
};

/**
 * Realiza una llamada a la url informada y devuelve el resultado.
 * En caso de error se puede reintentar.
 */
const callPostExternalApi = async <T, D = IDefaultData>({
	url,
	headers,
	params,
	data,
	numTries,
}: ExternalApiParams<D>): Promise<ProcessedData<T>> => {
	try {
		const callResponse = await axiosInstance.post(url, data, {
			params,
			headers,
		});

		return processResponse(callResponse);
	} catch (err) {
		return numTries && numTries > 0
			? callPostExternalApi({ url, headers, params, data, numTries: numTries - 1 })
			: processError(err as AxiosError<T>);
	}
};

/**
 * Realiza una llamada a la url informada y devuelve el resultado.
 * En caso de error se puede reintentar.
 */
const callPutExternalApi = async <T, D = IDefaultData>({
	url,
	headers,
	params,
	data,
	numTries,
}: ExternalApiParams<D>): Promise<ProcessedData<T>> => {
	try {
		const callResponse = await axiosInstance.put(url, data, {
			params,
			headers,
		});
		return processResponse(callResponse);
	} catch (err) {
		return numTries && numTries > 0
			? callPutExternalApi({ url, headers, params, data, numTries: numTries - 1 })
			: processError(err as AxiosError<T>);
	}
};

/**
 * Realiza una llamada a la url informada y devuelve el resultado.
 * En caso de error se puede reintentar.
 */
const callDeleteExternalApi = async <T, D = IDefaultData>({
	url,
	headers,
	params,
	data,
	numTries,
}: ExternalApiParams<D>): Promise<ProcessedData<T>> => {
	try {
		const callResponse = await axiosInstance.delete(url, {
			data,
			params,
			headers,
		});
		return processResponse(callResponse);
	} catch (err) {
		return numTries && numTries > 0
			? callDeleteExternalApi({ url, headers, params, data, numTries: numTries - 1 })
			: processError(err as AxiosError<T>);
	}
};

export { callDeleteExternalApi, callGetExternalApi, callPostExternalApi, callPutExternalApi };
