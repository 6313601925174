import { DATAFOLDER } from '../constants/routes.constants';
import type { IActo } from '../interfaces/model/IActo';
import type { IHome } from '../interfaces/model/IHome';

import { callGetExternalApi } from '../utils/externalApiCall';

/**
 * Listado de rutas del backend
 */
const getBackendUrl = () => ({
	GET_HOME_CARDS: `${DATAFOLDER}/data/home.json`,
	GET_HOME_ACTOS: `${DATAFOLDER}/data/actos.json`,
});

export const getHomeCards = async () => callGetExternalApi<IHome>({ url: getBackendUrl().GET_HOME_CARDS });
export const getHomeActs = async () => callGetExternalApi<IActo>({ url: getBackendUrl().GET_HOME_ACTOS });
