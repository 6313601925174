export const convertDateFormat = (cadenaFecha: string): string => {
	const [dia, mes, anio] = cadenaFecha.split('/').map(Number);
	const nombresMeses = [
		'enero',
		'febrero',
		'marzo',
		'abril',
		'mayo',
		'junio',
		'julio',
		'agosto',
		'septiembre',
		'octubre',
		'noviembre',
		'diciembre',
	];

	const nombresDias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

	const nombreMes = nombresMeses[mes - 1];
	const nombreDia = nombresDias[new Date(anio, mes - 1, dia).getDay()];

	return `${nombreDia} ${dia} ${nombreMes} ${anio}`;
};
